import { FC } from 'react'
import { toast } from './base/Toast'
import SearchHighlight from './SearchHighlight'

type BarcodeTextType = {
  value: string | number | undefined
}

const BarcodeText: FC<BarcodeTextType> = ({ value }) => {
  if (!value) {
    return null
  }
  return (
    <span
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        navigator.clipboard.writeText(`${value}`)
        toast('Set clipboard')
      }}
      className='fw-semibold cursor-pointer'
    >
      <span className='fa fa-barcode' />{' '}
      <SearchHighlight>{`${value}`}</SearchHighlight>{' '}
      <span className='fa text-muted fa-copy' />
    </span>
  )
}

export default BarcodeText
