import { FC, useState } from 'react'
import cx from 'classnames'
import ActiveLink from 'components/base/ActiveLink'
import Logo from 'components/Logo'
import SiteSearch from 'components/SiteSearch'
import { useFlags, useFlagsmith } from 'flagsmith/react'
import Head from 'next/head'
import DeviceSearcher from 'components/DeviceSearcher'
import { logout, useUser } from 'common/services/useUser'
import { getStore } from 'common/store'
import { setToken } from 'common/services/useToken'
import Router from 'next/router'
import Dropdown from 'components/dropdown/Dropdown'
import DropdownItem from 'components/dropdown/DropdownItem'
import Link from 'next/link'

type ComponentType = {}

const Nav: FC<ComponentType> = ({}) => {
  const [isActive, setIsActive] = useState(false)
  const { data } = useUser({})
  const flags = useFlags(['devmode', 'banner', 'report_subscription'])
  const [show, setShow] = useState(false)
  const flagsmith = useFlagsmith()
  const bannerJSON = flagsmith.getValue<{ text: string }>('banner', {
    fallback: { text: '' },
    json: true,
  })
  return (
    <>
      <Head>
        <title>Operations Admin</title>
      </Head>
      <div className='px-9 py-4'>
        <div className='row'>
          <div className='col-8'>
            <div className='flex-row align-items-center'>
              <Link className='cursor-pointer' href={'/dashboard/'}>
                <a href='/dashboard/' className='cursor-pointer d-block'>
                  <Logo />
                </a>
              </Link>
              <div className='flex-fill ms-5'>
                <SiteSearch />
              </div>
            </div>
          </div>
          <div className='col-4 d-flex justify-content-end'>
            <DeviceSearcher />
          </div>
        </div>
      </div>
      <nav className='navbar py-0 bg-light200  navbar-expand-md  mb-4'>
        <>
          <button
            onClick={() => setIsActive(!isActive)}
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarCollapse'
            aria-controls='navbarCollapse'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div
            className={cx({ collapse: !isActive }, 'navbar-collapse')}
            id='navbarCollapse'
          >
            <ul className='navbar-nav flex-fill me-auto mb-2 mb-md-0'>
              {[
                { exact: true, label: 'Home', url: '/dashboard' },
                { label: 'Mail Batches', url: '/operations/' },
                {
                  label: 'Customers',
                  url: '/customers/',
                },
                {
                  label: 'Mail',
                  url: '/mail/',
                },
                {
                  label: 'KYC',
                  url: '/kyc/',
                },
                {
                  enabled: flags.report_subscription.enabled,

                  label: 'Compliance',
                  url: '/compliance/',
                },
                {
                  label: 'Printer',
                  url: '/label/',
                },

                {
                  label: 'Partners',
                  url: '/partners/',
                },
                {
                  enabled: flags.devmode.enabled,
                  label: 'Settings',
                  url: '/developer-settings/',
                },
              ]
                .filter((v) => v.enabled !== false)
                .map((v) => (
                  <li key={v.label} className='nav-item ps-7'>
                    <ActiveLink exact={v.exact} href={v.url}>
                      <a
                        href={v.url}
                        className='nav-link cursor-pointer'
                        aria-current='page'
                      >
                        {v.label}
                      </a>
                    </ActiveLink>
                  </li>
                ))}
            </ul>
            <ul className='navbar-nav d-flex align-items-center me-auto mb-2 mb-md-0'>
              <li className='list-unstyled mx-5'>
                <Dropdown
                  dropdownClassName='right auto'
                  dropdownContent={
                    <DropdownItem
                      className='px-4'
                      onClick={() => {
                        Router.replace('/')
                        logout(getStore(), {})
                          .then(() => {
                            setToken(null)
                            localStorage.clear()
                          })
                          .then(() => {})
                      }}
                    >
                      <span className='fa fa-sign-out text-muted me-1' />{' '}
                      <span className='body-secondary'>Sign out</span>
                    </DropdownItem>
                  }
                  show={show}
                  setShow={setShow}
                >
                  <div className='cursor-pointer' onClick={() => setShow(true)}>
                    <div className='text-muted fs-sm'>Your Account</div>
                    <div className=' flex-row align-items-center fw-semibold'>
                      {data?.user?.first_name} {data?.user?.last_name}
                      <span className='fa fa-caret-down text-muted ms-2' />
                    </div>
                  </div>
                </Dropdown>
              </li>
            </ul>
          </div>
        </>
      </nav>
      {!!flags.banner.enabled && !!bannerJSON.text && (
        <div
          className='flex-row bg-danger mb-4 text-white justify-content-center align-items-center'
          style={{
            height: 44,
          }}
        >
          <h5 className='mb-0'>{bannerJSON.text}</h5>
        </div>
      )}
    </>
  )
}

export default Nav
