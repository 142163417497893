import { MailQueueItemSummary, Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { toParam } from 'common/utils/urls/param'
import transformPagination from 'common/utils/transformPagination'
import toFormData from 'common/toFormData'

export const queueItemService = service
  .enhanceEndpoints({ addTagTypes: ['QueueItem'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createQueueItem: builder.mutation<
        Res['queueItem'],
        Req['createQueueItem']
      >({
        invalidatesTags: [{ id: 'LIST', type: 'QueueItem' }],
        queryFn: async (query, { dispatch }, _2, baseQuery) => {
          const { batch, image, ...rest } = query
          const body = {
            ...rest,
          }
          const formData = toFormData(body)
          if (image) {
            formData.append('file', image as any)
          }
          return baseQuery({
            body: formData,
            method: 'POST',
            url: `operations/batches/${batch}/queueitems`,
          })
        },
      }),
      getQueueItems: builder.query<Res['queueItems'], Req['getQueueItems']>({
        providesTags: [{ id: 'LIST', type: 'QueueItem' }],
        query: ({ batch, ...rest }) => ({
          url: `operations/batches/${batch}/queueitems?${toParam({ ...rest })}`,
        }),
        //@ts-ignore
        transformResponse: (res, _, req) => {
          return {
            ...transformPagination<MailQueueItemSummary>(req, res),
            ts: req.lock_duration ? Date.now() : null,
          }
        },
        // queryFn: () => {
        //   return { data: responses.queueItems }
        // },
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getQueueItems(
  store: any,
  data: Req['getQueueItems'],
  options?: Parameters<
    typeof queueItemService.endpoints.getQueueItems.initiate
  >[1],
) {
  return store.dispatch(
    queueItemService.endpoints.getQueueItems.initiate(data, options),
  )
}
export async function createQueueItem(
  store: any,
  data: Req['createQueueItem'],
  options?: Parameters<
    typeof queueItemService.endpoints.createQueueItem.initiate
  >[1],
) {
  return store.dispatch(
    queueItemService.endpoints.createQueueItem.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useCreateQueueItemMutation,
  useGetQueueItemsQuery,
  // END OF EXPORTS
} = queueItemService

/* Usage examples:
const { data, isLoading } = useGetQueueItemsQuery({ id: 2 }, {}) //get hook
const [createQueueItems, { isLoading, data, isSuccess }] = useCreateQueueItemsMutation() //create hook
queueItemService.endpoints.getQueueItems.select({id: 2})(store.getState()) //access data from any function
*/
