import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { getApi } from './api'
import { persistReducer, persistStore } from 'redux-persist'
import { Persistor } from 'redux-persist/es/types'
import { service } from './service'
import { RedirectSlice } from './services/useRedirect'
import { DevicesSlice } from './services/useDevices'
import { activeDeviceSlice } from './services/useActiveDevice'
import { tokenSlice } from './services/useToken'
import { devSettingsSlice } from './services/useDevSettings'
import { sortedMailsSlice } from './services/useSortedMails'
import { scannerIPSlice } from './services/useScannerIP'
// END OF IMPORTS

const createStore = () => {
  const storage =
    getApi().reduxStorage ||
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('redux-persist/lib/storage').default
  const reducer = combineReducers({
    [service.reducerPath]: service.reducer,
    Devices: DevicesSlice.reducer,
    activeDevice: activeDeviceSlice.reducer,
    devSettings: devSettingsSlice.reducer,
    redirect: RedirectSlice.reducer,
    scannerIP: scannerIPSlice.reducer,
    sortedMails: sortedMailsSlice.reducer,
    token: tokenSlice.reducer,
    // END OF REDUCERS
  })

  return configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(service.middleware)
        // END OF MIDDLEWARE
        .concat(getApi().middlewares || []),
    // @ts-ignore typescript is confused by the turnary
    reducer:
      typeof window !== 'undefined'
        ? persistReducer(
            {
              key: 'root',
              storage,
              version: 1,
              whitelist: [
                'user',
                'token',
                'devSettings',
                'scannerIP',
                'sortedMails',
              ],
            },
            reducer,
          )
        : reducer,
  })
}

type StoreType = ReturnType<typeof createStore>
let _store: StoreType
let _persistor: Persistor
export const getPersistor = () => _persistor
export const getStore = function (): StoreType {
  if (_store) return _store
  _store = createStore()
  _persistor = persistStore(_store)
  return _store
}

export type StoreStateType = ReturnType<StoreType['getState']>
