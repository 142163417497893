import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'
import { Project } from 'common/project'

type InitialStateType = Res['scannerIP'] | null

const initialState = Project.scannerIPs[0].value

export const scannerIPSlice = createSlice({
  initialState,
  name: 'scannerIP',
  reducers: {
    setScannerIP(state, action: PayloadAction<Req['setScannerIP']>) {
      state = action.payload
      return state
    },
  },
})

export const scannerIPActions = scannerIPSlice.actions
export const useScannerIPActions = () => {
  const dispatch = useDispatch()
  const setScannerIP = useCallback(
    (payload: Req['setScannerIP']) => {
      return dispatch(scannerIPActions.setScannerIP(payload))
    },
    [dispatch],
  )
  return { setScannerIP }
}

const selectScannerIP = (state: StoreStateType) => state.scannerIP

export const useScannerIP = () => {
  const { setScannerIP } = useScannerIPActions()
  const scannerIP = useSelector(selectScannerIP)
  return useMemo(() => ({ scannerIP, setScannerIP }), [setScannerIP, scannerIP])
}
