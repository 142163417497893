import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { getStore } from 'common/store'
import { mailItemService } from './useMailItem'
import { mailItemByBarcodeService } from './useMailItemByBarcode'
import { queueItemService } from './useQueueItem'

export const mailItemSpamService = service
  .enhanceEndpoints({ addTagTypes: ['MailItemSpam'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createMailItemSpam: builder.mutation<
        Res['mailItemSpam'],
        Req['createMailItemSpam']
      >({
        invalidatesTags: [{ id: 'LIST', type: 'MailItemSpam' }],
        query: (query: Req['createMailItemSpam']) => {
          return query.type === 'MAIL_ITEM'
            ? {
                body: query,
                method: 'POST',
                url: `mails/${query.id}/actions/SPAM`,
              }
            : {
                body: query,
                method: 'POST',
                url: `operations/batches/${query.batchId}/queueitems/${query.id}/actions/spam`,
              }
        },
        transformResponse: (res, _, req) => {
          getStore().dispatch(
            mailItemService.util.invalidateTags([
              { id: 'LIST', type: 'MailItem' },
              { id: req.id, type: 'MailItem' },
            ]),
          )
          getStore().dispatch(
            mailItemByBarcodeService.util.invalidateTags([
              { type: 'MailItemByBarcode' },
            ]),
          )
          getStore().dispatch(
            queueItemService.util.invalidateTags([
              { id: req.id, type: 'QueueItem' },
              { id: 'LIST', type: 'QueueItem' },
            ]),
          )
          return res
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export async function createMailItemSpam(
  store: any,
  data: Req['createMailItemSpam'],
  options?: Parameters<
    typeof mailItemSpamService.endpoints.createMailItemSpam.initiate
  >[1],
): Promise<Res['mailItemSpam']> {
  return store.dispatch(
    mailItemSpamService.endpoints.createMailItemSpam.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useCreateMailItemSpamMutation,
  // END OF EXPORTS
} = mailItemSpamService

/* Usage examples:
const { data, isLoading } = useGetMailItemSpamQuery({ id: 2 }, {}) //get hook
const [createMailItemSpam, { isLoading, data, isSuccess }] = useCreateMailItemSpamMutation() //create hook
mailItemSpamService.endpoints.getMailItemSpam.select({id: 2})(store.getState()) //access data from any function
*/
