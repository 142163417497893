import dayjs from 'dayjs'
import React, { FC, useState } from 'react'
import { sortBy, uniq } from 'lodash'

import { getStore } from 'common/store'
import useOnScan from 'project/useOnScan'
import QRReader from 'components/QRReader'
import markAsSpam from 'project/markAsSpam'
import QRIcon from 'components/icons/QRIcon'
import { toast } from 'components/base/Toast'
import Button from 'components/base/forms/Button'
import { openConfirm } from 'components/base/Modal'
import CompanyStatus from 'components/CompanyStatus'
import { ErrorMessage } from 'components/base/Messages'
import InputGroup from 'components/base/forms/InputGroup'
import { getAddressParts } from '../../pages/mail/forwards'
import formatDateAndTime from 'common/utils/date/formatDateAndTime'
import { MailItemSummary, PagedResponse } from 'common/types/responses'
import { getMailItemByBarcode } from 'common/services/useMailItemByBarcode'
import { safeParseEventValue } from 'common/utils/forms/safeEventParseValue'
import BarcodeText from 'components/BarcodeText'
type ScannableMailTableType = {
  items: PagedResponse<MailItemSummary> | undefined
  scanned?: MailItemSummary[] | undefined
  setScanned?: (value: MailItemSummary[]) => void
  requiresScan?: boolean
  disableRemoveProcessed?: boolean
  processed?: MailItemSummary[]
  renderLabel?: (mail: MailItemSummary) => boolean | undefined
  hideCamera?: boolean
  scanToAdd?: boolean
  hideCompanyName?: boolean
  onScan?: (mail: MailItemSummary) => boolean | undefined
  onItemsChanged?: (items: PagedResponse<MailItemSummary>) => void
  processedLabel?: string
  scannedLabel?: string
  error: any
  disableScan?: boolean
}

const MailActionTable: FC<ScannableMailTableType> = ({
  children,
  disableRemoveProcessed,
  disableScan,
  error,
  hideCamera,
  hideCompanyName,
  items,
  onItemsChanged,
  onScan,
  processed,
  processedLabel,
  renderLabel,
  requiresScan,
  scanToAdd,
  scanned,
  scannedLabel = 'Scanned',
  setScanned,
}) => {
  const [paused, setPaused] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [barcode, setBarcode] = useState('')

  const handleTextScan = (text: string) => {
    if (disableScan) {
      toast("Previous mail hasn't been uploaded yet", null, 'danger')
      return
    }
    setBarcode('')
    if (scanToAdd) {
      if (items?.results?.find((v) => v.barcode_id === text)) {
        toast(`Mail item already ${scannedLabel}`, null)
        return
      }
      setIsLoading(true)
      return getMailItemByBarcode(
        getStore(),
        { id: text },
        { forceRefetch: true },
      )
        .then((res) => {
          if (res.data) {
            if (res.data.status === 'SPAM') {
              toast('Mail item is marked as spam', null, 'danger')
              return
            }
            if (onScan?.(res.data) === false) {
              return
            }
            onItemsChanged?.({
              ...(items || {
                count: 1,
                currentPage: 1,
                next: '',
                previous: '',
                results: [],
                totalPages: 1,
              }),
              results: [res.data as MailItemSummary].concat(
                items?.results || [],
              ),
            })
          } else {
            toast(
              `Could not find ${scannedLabel} mail item: ${text}`,
              null,
              'danger',
            )
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      const matchingItem = items?.results?.find((v) => v.barcode_id === text)
      if (matchingItem) {
        setScanned?.(
          uniq((scanned || []).concat([matchingItem])).filter(
            (v) => !processed?.includes(v),
          ),
        )
        toast(`Mail item ${scannedLabel}`, null, 'success')
      } else {
        toast(
          `Could not find ${scannedLabel} mail item: ${text}`,
          null,
          'danger',
        )
      }
    }
  }

  useOnScan(handleTextScan)

  const handleMarkAsSpam = (mailItem: MailItemSummary) => {
    markAsSpam({
      batchId: ``,
      id: mailItem.id,
      type: 'MAIL_ITEM',
    }).then((res: any) => {
      if (!res.error) {
        onItemsChanged?.({
          ...(items || {
            count: 1,
            currentPage: 1,
            next: '',
            previous: '',
            results: [],
            totalPages: 1,
          }),
          results: (items?.results || []).filter((v) => v.id !== mailItem.id),
        })
        toast('Mail item marked as spam', null, 'success')
      }
    })
  }

  const processBarcode = async () => {
    if (!isLoading) {
      const codes = barcode.split(',')
      for (let i = 0; i < codes.length; i++) {
        const barcode = codes[i]
        await handleTextScan(barcode)
      }
    }
  }

  return (
    <div className='mt-5'>
      {requiresScan ? (
        <div className='d-flex flex-column flex-md-row align-items-md-end gap-4 mb-4'>
          <form
            onSubmit={async (e) => {
              e.preventDefault()
              processBarcode()
            }}
            className='flex-row flex-fill align-items-end'
          >
            <div className='flex-1 flex-md-grow-0'>
              <InputGroup
                id='barcode'
                className='w-100'
                inputProps={{
                  style: { minWidth: 200 },
                }}
                value={barcode}
                onChange={(e) => {
                  setBarcode(safeParseEventValue(e))
                }}
                title='Barcode'
              />
            </div>
            <Button
              type='submit'
              disabled={!barcode || isLoading}
              className='ms-2'
            >
              Submit
            </Button>
          </form>
          {!hideCamera && (
            <div className='me-4' style={{ width: 300 }}>
              <strong>
                Start scanning to begin or enter the barcode manually
              </strong>
              <div
                style={{ height: 200, width: 300 }}
                className='rounded mt-4 d-flex justify-content-center align-items-center overflow-hidden'
              >
                <div className='position-relative bg-dark d-flex h-100 w-100'>
                  {!paused ? (
                    <QRReader
                      className='w-100 h-100 d-flex align-items-center'
                      onResult={handleTextScan}
                    />
                  ) : (
                    <div className='d-flex align-items-center justify-content-center w-100'>
                      <QRIcon />
                    </div>
                  )}

                  <div className='position-absolute d-flex justify-content-center align-items-start left-0 right-0 bottom-0 top-0'>
                    <Button className='mt-4' onClick={() => setPaused(!paused)}>
                      {paused ? 'Start' : 'Stop'} Scanning
                    </Button>
                  </div>
                </div>
              </div>
              <div className='flex-row mt-4'>
                <Button
                  onClick={() => {
                    if (scanToAdd) {
                      handleTextScan('test')
                    } else {
                      const unscanned = items?.results?.find(
                        (v) =>
                          !(scanned || []).includes(v) &&
                          !processed?.includes(v),
                      )
                      if (unscanned) {
                        setScanned?.((scanned || []).concat([unscanned]))
                        toast(`Mail item ${scannedLabel}`, null, 'success')
                      }
                    }
                  }}
                >
                  Mock Scan
                </Button>

                <Button
                  theme='danger'
                  className='ms-2'
                  onClick={() => {
                    toast('Could not find scanned mail item', null, 'danger')
                  }}
                >
                  Mock Failed Scan
                </Button>
              </div>
            </div>
          )}
          {children}
        </div>
      ) : (
        children
      )}

      <div className='flex-fill'>
        <ErrorMessage>{error}</ErrorMessage>
        <table className='w-100 rounded mb-2'>
          <thead>
            <tr>
              <th style={{ width: 300 }} className='text-nowrap'>
                Mail Item
              </th>
              <th
                style={{ width: 300 }}
                className='text-nowrap d-none d-md-table-cell'
              >
                Processed by
              </th>
              <th
                style={{ width: 180 }}
                className='text-nowrap d-none d-md-table-cell'
              >
                Received
              </th>
              <th style={{ width: 250 }} className='text-nowrap'>
                Location
              </th>
            </tr>
          </thead>
          <tbody>
            {sortBy(items?.results, (v) => -dayjs(v.created_at).valueOf())?.map(
              (mailItem, i) => (
                <tr key={mailItem.id}>
                  <td>
                    <div>
                      <span className='fw-semibold'>
                        {mailItem.metadata?.sender_name || 'Mail Item'}
                      </span>{' '}
                      <span className='fs-xs'>
                        {i + 1}/{items?.results.length}
                      </span>
                    </div>
                    {!!mailItem.barcode_id && (
                      <div>
                        <BarcodeText value={mailItem.barcode_id} />
                      </div>
                    )}
                    <div>{mailItem.metadata?.document_title}</div>
                    <div className='flex-row gap-2 align-items-center'>
                      <CompanyStatus status={mailItem.subscription?.status} />
                      {!hideCompanyName && (
                        <div>{getAddressParts(mailItem)?.join(', ')} </div>
                      )}
                    </div>
                  </td>
                  <td className='d-none d-md-table-cell'>
                    {mailItem.created_by}
                  </td>
                  <td className='d-none d-md-table-cell'>
                    {formatDateAndTime(mailItem.created_at)}
                  </td>
                  <td>
                    {requiresScan && (
                      <div className='flex-row gap-1 align-items-center'>
                        <div className='flex-fill'>{mailItem.location}</div>
                        {renderLabel?.(mailItem)}

                        <Button
                          disabled={!mailItem}
                          onClick={() => handleMarkAsSpam(mailItem)}
                          theme='danger'
                        >
                          Mark as Spam
                        </Button>

                        {!!scanToAdd && (
                          <Button
                            disabled={
                              processed?.includes(mailItem) &&
                              disableRemoveProcessed
                            }
                            onClick={() => {
                              openConfirm(
                                'Remove mail item from list?',
                                'This will remove the mail item from the list of items to process.',
                                () => {
                                  closeModal()
                                  onItemsChanged?.({
                                    ...(items || {
                                      count: 1,
                                      currentPage: 1,
                                      next: '',
                                      previous: '',
                                      results: [],
                                      totalPages: 1,
                                    }),
                                    results: (items?.results || []).filter(
                                      (v) => v.id !== mailItem.id,
                                    ),
                                  })
                                },
                              )
                            }}
                            theme='danger'
                          >
                            <i className='fa fa-trash' />
                          </Button>
                        )}
                        <div className='text-center px-4'>
                          {!scanToAdd && (
                            <>
                              {(scanned || []).includes(mailItem) &&
                                !processed?.includes(mailItem) && (
                                  <div className='text-success fw-semibold'>
                                    <span>{scannedLabel}</span>
                                  </div>
                                )}
                            </>
                          )}

                          {processed?.includes(mailItem) && (
                            <div className='text-info fw-semibold'>
                              <span>{processedLabel}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
        {children}
      </div>
    </div>
  )
}

export default MailActionTable
