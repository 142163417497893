import { Req } from 'common/types/requests'
import { openConfirm } from 'components/base/Modal'
import { createMailItemSpam } from 'common/services/useMailItemSpam'
import { getStore } from 'common/store'

export default function (data: Req['createMailItemSpam']) {
  return new Promise((resolve) => {
    openConfirm(
      'Mark as spam',
      `Marking an item as spam will not show the mail item to the customer, are you sure?`,
      () => {
        createMailItemSpam(getStore(), data).then((mailItemByBarcode) => {
          resolve(mailItemByBarcode)
        })
        closeModal()
      },
    )
  })
}
